import request from "@/utils/request";
import method from "@/const/request/requestMethod";


//订单售后补偿商家
export function returnStoreList(page){
	return request({
	    url: "/freshOrderAfterService/returnStoreList?page="+page,
	    method: method.GET,
	})
}
