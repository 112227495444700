<template>
  <div class="container">
    <div class="head">
      <div class="search_box" style="margin-bottom: 20px;">

        <div class="search_main">
        </div>
        <div class="search_main">
        </div>
      </div>
    </div>
    <el-card class="body">

      <div class="text">
        <!-- 查询 -->
        <el-table :data="tableData" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }" style="width: 100%;font-family: PingFangRoutine;">
          <el-table-column prop="id" label="id" width="200"></el-table-column>
          <el-table-column prop="createTime" label="日期" width="200"></el-table-column>
          <el-table-column prop="afterReason" label="原因" width="200"></el-table-column>
          <el-table-column prop="freshStore.name" label="店铺名称" width="200"></el-table-column>
          <el-table-column prop="money" label="补偿金额">
            <template slot-scope="scope">
               <span >{{ scope.row.afterRealAmount*0.01 }}</span>
            </template>
          </el-table-column>

        </el-table>
      </div>

    </el-card>
    <div style="height: 10px"></div>
    <div class="footer">
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
       </div>
  </div>
</template>

<script>
import * as freshOrderAfterServiceApi from "@/api/freshOrderAfterService"
import "../../font-style/font.css";
export default {
  data() {
    return {
      total: null, //总条数
      tableData: [], //表格数据
    };
  },
  created() {
    this.list(1);
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;;fontSize: '14px'";
      }
    },
    // 售后列表
    list(page) {
      freshOrderAfterServiceApi.returnStoreList(page).then(res=>{
        this.total = res.data.total * 1;
        this.tableData = res.data.records;
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.list(val);
    },

  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/common.less";

.box-card {
  width: 100%;
}
.head{
  margin-bottom: 10px;
  background-color: white;
  padding-left: 20px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 0px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
/deep/ .tabs {
  margin-left: 20px;
  margin-top: 30px;
}
/deep/ .el-table th.el-table__cell > .cell {
  text-align: center;
}
/deep/ .el-table__cell {
  text-align: center;
}
/deep/ .el-table td.el-table__cell div{
  text-align: center !important;

}
</style>
